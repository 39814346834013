import React, { useContext, useRef } from "react"
import { RichText, Link } from 'prismic-reactjs'
import { Link as RouteLink } from "gatsby"
import { linkResolver } from '~utils/linkResolver'
import { resolveLink } from '~utils/resolveLink'
import styled from "styled-components"
import { css, jsx } from '@emotion/react'

import theme from "~theme/theme"
import { MetadataContext } from "~components/layout"
import useBreakpoint from '~utils/use-breakpoint'
import htmlSerializer from '~utils/html-serializer'
import spanSerializer from '~utils/span-serializer'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import CustomForm from "~components/CustomForm"
import VideoPlayer from "~components/VideoPlayer"
import ArticleProgress from "~components/ArticleProgress"
import RichTextSanity from "~components/richText"
import RichTextArticle from "~components/richTextArticle"
import useSiteSettings from "~utils/useSiteSettings"
import Image from "~components/image"
// import PdfDownload from "~components/PdfDownload"


const SideImage = ({ section }) => (
  <SideImageContainer>
    <figure>
      <img src={section.primary.image.url} alt={section.primary.caption ? section.primary.caption.text : ''} />
      <figcaption>{RichText.render(section.primary.caption)}</figcaption>
    </figure>
  </SideImageContainer>
)

const Article = ({ location, title, body, contributors, related_articles, footnotes, id, slug, content }) => {
  const metaData = useContext(MetadataContext)
  const articleRef = useRef();
  const { sm } = useBreakpoint()
  const mobile =  `@media(max-width: 600px)`
  const { aboutTextShort } = useSiteSettings()
  return (
    <CurrentArticle className='current-article' ref={articleRef}>
      {!sm &&
        <h3>{title}<br /></h3>
      }
      <RichTextArticle content={content?.text} css={`
        > p, > h3, > h4, > h5, > ul, > ol {
          width: calc(50% - 10px);
          ${mobile}{
            width: 100%;
          }
        }
        h3{
          font-size: ${theme.typeSizes.medium};
          margin: 1rem 0;
          &:first-child{
            margin-top: 0;
          }
        }
        h4{
          font-size: ${theme.typeSizes.smedium};
          margin: 1rem 0;
          &:first-child{
            margin-top: 0;
          }
        }
        h5{
          font-size: ${theme.typeSizes.small};
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin: 1rem 0 0.5em;
          font-weight: 400;
          &:first-child{
            margin-top: 0;
          }
        }
        figure {
          margin: 0;
          figcaption {
            margin-top: 0.7rem;
            caption-side: bottom;
            p {
              max-width: 100%;
              font-size: ${theme.typeSizes.xsmall};
            }
          }
        }
        a {
          text-decoration: none;
        }
        pre {
          white-space: normal;
          font-family: inherit;
          font-size: ${theme.typeSizes.large};
          text-indent: -0.55em;
          padding-left: 0.55em;
          margin: 0;
          max-width: 450px;
        }
        ol, ul{
          padding-left: 1em;
          li{
            margin-bottom: 0.5em;
          }
        }
        `} />
      <p style={{marginBottom: '1em', marginTop: '1em'}}>&mdash;</p>
      {content?.authors?.map((a, index) => (
        <Contributor key={index}>
          <ContributorImage image={a.image?.asset?.url} />
          <div>
            <p className="x-small">
              {a?.title}
            </p>
            <RichTextSanity content={a?.shortBio} />
          </div>
        </Contributor>
      )
      )}
      <ArticleFooter>
        {!sm &&
          <RouteLink style={{ border: 0 }} to="/">
            <svg width="161" height="117" viewBox="0 0 161 117" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M160.9 50.8H27L68 9.8L58.2 0L0 58.2L58.2 116.3L68 106.5L26.2 64.7H160.9V50.8Z" fill="black"/>
            </svg>
          </RouteLink>
        }
        <Col>
        <p className="align-top share-links">
            <a 
              onClick={() => trackCustomEvent({
                category: "Twitter share button",
                action: "Click",
                label: "Share to Twitter",
              })} 
              href={`https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(location.href)}&text=${encodeURIComponent(title)}&url=${encodeURIComponent(location.href)}&via=${encodeURIComponent(metaData.twitter_handle)}`} 
              target="_blank">
                <svg viewBox="0 0 512 512"><path d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z"/></svg>
              Share to Twitter
            </a><br/>
            <a 
              onClick={() => trackCustomEvent({
                category: "LinkedIn share button",
                action: "Click",
                label: "Share to LinkedIn",
              })} 
             href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}`} 
             target="_blank">
               <svg viewBox="0 0 512 512"><path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"/></svg>
               Share to LinkedIn
            </a><br />
            <a
              onClick={() => trackCustomEvent({
                category: "Facebook share button",
                action: "Click",
                label: "Share to Facebook",
              })} 
              href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`} 
              target="_blank">
                <svg viewBox="0 0 512 512" style={{
                  
                  }}><path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"/></svg>
                Share to Facebook
            </a>
          </p>
          <br />
          <RichTextSanity content={aboutTextShort} />
          <br />
          {content?.related?.length &&
          <>
            <Similar><Dot/>Similar Stories</Similar>
          {content?.related?.map((o, index) => (
            <p>
              <SimilarArticle key={index} className="underline" to={resolveLink(o._type, o.slug.current)}>
                <div>
                  <Image id={o?.content?.seo?.socialImage?.asset?._ref ?? o?.content?.seo?.socialImage?.asset?._id} alt='' aspectRatio={1}/>
                </div>
                <div>
                  <span>{o.title}</span>
                </div>
                
              </SimilarArticle>
            </p>
          ))}
          </>
          }
          <p style={{marginTop: '2rem'}}>
          <svg width="28" height="28" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.603 0C22.921 0 15.281 3.213 9.377 9.203C3.299 15.367 0 23.355 0 31.689C0 40.111 3.212 47.925 9.29 54.002C15.368 60.08 23.268 63.379 31.603 63.379C39.937 63.379 48.012 60.08 54.263 53.916C60.167 48.099 63.292 40.372 63.292 31.689C63.292 23.094 60.167 15.281 54.176 9.29C48.099 3.212 40.285 0 31.603 0ZM31.689 5.73C38.809 5.73 45.147 8.421 50.095 13.371C54.957 18.232 57.561 24.657 57.561 31.689C57.561 38.808 55.043 45.06 50.182 49.835C45.059 54.87 38.461 57.562 31.689 57.562C24.831 57.562 18.406 54.871 13.457 49.921C8.508 44.972 5.73 38.461 5.73 31.689C5.73 24.831 8.508 18.32 13.457 13.283C18.319 8.335 24.57 5.73 31.689 5.73Z" fill="black"/>
            <path d="M31.281 26.4229C29.491 23.1589 26.437 21.8599 22.892 21.8599C17.732 21.8599 13.625 25.5099 13.625 31.6879C13.625 37.9709 17.486 41.5169 23.067 41.5169C26.648 41.5169 29.702 39.5509 31.386 36.5679L27.455 34.5669C26.577 36.6719 25.243 37.3049 23.559 37.3049C20.645 37.3049 19.311 34.8829 19.311 31.6879C19.311 28.4949 20.435 26.0719 23.559 26.0719C24.401 26.0719 26.086 26.5279 27.069 28.6349L31.281 26.4229Z" fill="black"/>
            <path d="M49.302 26.4229C47.512 23.1589 44.458 21.8599 40.913 21.8599C35.753 21.8599 31.646 25.5099 31.646 31.6879C31.646 37.9709 35.507 41.5169 41.088 41.5169C44.669 41.5169 47.723 39.5509 49.407 36.5679L45.476 34.5669C44.598 36.6719 43.264 37.3049 41.58 37.3049C38.666 37.3049 37.332 34.8829 37.332 31.6879C37.332 28.4949 38.456 26.0719 41.58 26.0719C42.422 26.0719 44.107 26.5279 45.09 28.6349L49.302 26.4229Z" fill="black"/>
          </svg>
          <br/>
            Cities People Love is licensed under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">Creative Commons Attribution 4.0 International License.</a>
          </p>
          <br/>
          {content?.footnotes &&
            <div id="refs">
              {content?.footnotes.map(note => (
                <div className="x-small" css={css`
                  display: block;
                  margin-bottom: 1em;
                  p{
                    display: inline;
                    font-size: 13px;
                    line-height: 16px;
                  }
                  `}>
                [{note.number}] <RichTextSanity content={note.text}/>
                </div>
              ))}
            </div>
          }
        </Col>
        <Col>
        {/* <PdfDownload content={content} title={title} /> */}
        </Col>
      </ArticleFooter>
      <ArticleProgress articleRef={articleRef} diameter={32} strokeWidth={2.5} />
    </CurrentArticle>
  )
}

const ArticleSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  h3{
    font-size: ${theme.typeSizes.medium};
    margin: 1rem 0;
    &:first-child{
      margin-top: 0;
    }
  }
  h4{
    font-size: ${theme.typeSizes.smedium};
    margin: 1rem 0;
    &:first-child{
      margin-top: 0;
    }
  }
  h5{
    font-size: ${theme.typeSizes.small};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 1rem 0 0.5em;
    font-weight: 400;
    &:first-child{
      margin-top: 0;
    }
  }
  p > img {
    width: 100%;
  }
  figure {
    display: table;
    margin: 0;
    max-width: 500px;
    img {
      display: block;
      max-width: 100%;
    }
    figcaption {
      margin-top: 0.7rem;
      display: table-caption;
      caption-side: bottom;
      p {
        max-width: 100%;
        font-size: ${theme.typeSizes.xsmall};
      }
    }
  }
  a {
    text-decoration: none;
  }
  pre {
    white-space: normal;
    font-family: inherit;
    font-size: ${theme.typeSizes.large};
    text-indent: -0.55em;
    padding-left: 0.55em;
    margin: 0;
    max-width: 450px;
  }
  ol, ul{
    padding-left: 1em;
    li{
      margin-bottom: 0.5em;
    }
  }
`

const ArticleQuoteSection = styled(ArticleSection)`
  display: grid;
  margin: 1rem 0 1.5rem;
  p{
    margin: 0.5rem 0 0 1rem;
  }
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: none;
    /* change to block to remove sidebar quotes */
  }
`

const ArticleImageSection = styled(ArticleSection)`
  display: grid;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: ${props => props.layout === 'Align right' ? 'none' : 'grid'};
  }
  figure{
    max-width: ${props => props.size === 'Small' ? '200px' : 'none'};
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      grid-column: ${props => props.size === 'Large' ? 'span 2' : 'span 1'};
    }
    img{
      width: 100%;
    }
  }
`

const SideImageContainer = styled.div`
  display: none;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: block;
  }
`

const SideQuoteContainer = styled(SideImageContainer)`
  display: none;
  p{
    margin: 0.5rem 0 0 1rem;
  }
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    display: block;
    /* change to none to remove sidebar quotes */
  }
`

const Contributor = styled.div`
  display: flex;
  margin-bottom: 0.6rem;
  p {
    font-size: ${theme.typeSizes.xsmall};
  }
  div:last-child {
    flex: 1;
    margin-left: 1rem;
    p {
      &:first-child{
        margin-bottom:1em;
      }
      @media screen and (min-width: ${theme.breakpoints.sm}) {
        max-width: calc(50% - 100px);
      }
    }
  }
`

const ContributorImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  filter: grayscale(1);
  width: 100px;
  align-self: flex-start;
  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const CurrentArticle = styled.div`
  flex: 1;
  padding: 1rem;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    h3{
      display: none;
    }
  }
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    border-left: 1px solid;
  }
`
export const ArticleFooter = styled.div`
  display: grid;
  margin-top: 40px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    display: flex;

  }
  svg {
    max-height: 30px;
    max-width: 30px;
  }
  .share-links{
    svg{
      width: 20px;
      transform: translateY(0.2em);
      margin-right: 0.1em;
    }
  }
`
export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 10px;
  }
  p {
    line-height: 1.5;
    margin-top: auto;
    &.align-top{
      margin-top: 0;
    }
  }
`

const Similar = styled.h3`
  margin-bottom: 1rem;
`

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #e0b641;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.15em;
  border-radius: 10px;
`

const SimilarArticle = styled(RouteLink)`
  display: flex;
  border-bottom: none !important;
  &:hover{
    border-bottom: none;
    span{
      border-bottom: 1px solid;
    }
  }
  > div:first-child {
    width : 100px;
    flex-shrink: 0;
    margin-right: 1rem;
  }
`



export default Article
