import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { resolveLink } from '~utils/resolveLink'
import { withPreview } from 'gatsby-source-prismic-graphql';

import styled from "styled-components"
import moment from "moment"
import theme from "~theme/theme"
import useBreakpoint from '~utils/use-breakpoint'

const Sidebar = ({ data, location }) => {
  const { sm } = useBreakpoint()

  const isCurrentPath = (location, url, index) => {
    return (sm && index === 0 && location.pathname === '/') ||
      url === location.pathname.replace(/\/$/, '')
  }

  const renderAuthors = doc => {
    if(doc.node.override_author){
      return(
        <p>
          {doc.node.override_author}
        </p>
      )
    }
    else{
      return(
        <p>
          {doc.node.contributors.map((contributor, index) => {
            if(contributor.contributor !== null){
              return (
                <span key={index}>{contributor.contributor.title[0].text}
                  {index === doc.node.contributors.length - 2 && ' & '}
                  {index !== doc.node.contributors.length - 2 && index !== doc.node.contributors.length - 1 && doc.node.contributors.length > 1 && ', '}
                </span>
              )
            }
          }
        )}
        </p>
      )
    }
  }

  return (
    <>
      {data.allSanityArticle.nodes.map( (doc, index) => {
        if(!doc) return null
        return(
          <ArticleLink key={doc._id} isCurrent={isCurrentPath(location, resolveLink(doc._type, doc.slug.current), index)}>
            <CoverLink to={resolveLink(doc._type, doc.slug.current)} />
            <h3 className="medium">{doc.title}</h3>
            <p>
            {doc.content?.authors?.map((author, index) => (
              <span key={index}>{author.title}
                {index === doc.content.authors.length - 2 && ' & '}
                {index !== doc.content.authors.length - 2 && index !== doc.content.authors.length - 1 && doc.content.authors.length > 1 && ', '}
              </span>
            ))}
            </p>
            {doc.content?.date && 
              <p>{moment(doc.content?.date).format('MMMM, YYYY')}</p>
            }
          </ArticleLink>
        )
      })}
    </>
  )
}

const query = graphql`
{
  allSanityArticle(sort: {order: DESC, fields: content___date}, filter: {content: {hideFromMenu: {ne: true}}}) {
    nodes {
      title
      _type
      _id
      slug {
        current
      }
      content {
        date
        hideFromMenu
        authors {
          title
        }
      }
    }
  }
}

`

const ArticleLink = styled.div`
  padding: 1rem;
  border-bottom: 1px solid;
  position: relative;
  background: ${({isCurrent}) => isCurrent ? theme.colours.highlight : 'transparent' };
  h3{
    margin-bottom: 0.3em;
  }
  p:last-child{
    margin-bottom: 0;
  }
`
const CoverLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
export default props => (
  <StaticQuery
    query={`${query}`}
    render={withPreview(data => <Sidebar data={data} {...props} />, query)}
  />
)
