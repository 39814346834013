import React, { useState, useEffect } from "react"
import styled from "styled-components"

import theme from "~theme/theme"

const ArticleProgressComponent = ({ articleRef, diameter, strokeWidth }) => {
  const radius = diameter / 2 - strokeWidth / 2
  const circumference = Math.PI * radius * 2

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (!articleRef.current) return
      const { height } = articleRef.current.getBoundingClientRect()
      const position = Math.max((window.scrollY - articleRef.current.offsetTop) / (height - window.innerHeight), 0)
      setProgress(position > 2 ? 2 : position)
    }
    updateHeight()

    window.addEventListener("scroll", updateHeight)
    return () => {
      window.removeEventListener("scroll", updateHeight)
    }
  }, [articleRef])


  return (
    <ArticleProgress>
      <svg
        viewBox={`0 0 ${diameter} ${diameter}`}
        width={diameter}
        height={diameter}
        className="circle-progress"
      >
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          stroke={theme.colours.black}
          fill="transparent"
          strokeWidth={strokeWidth}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: circumference * (1 - progress)
          }}
        />
      </svg>
    </ArticleProgress>
  )
}

const ArticleProgress = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
`

const Contributor = styled.div`
  display: flex;

  div:last-child {
    flex: 1;
    margin-left: 2rem;
    p {
      max-width: 50%;
    }
  }
`

export default ArticleProgressComponent
