import React from 'react';
import { Label, Input } from '~theme/common'

function TextInput({ name, label, placeholder, type, value, alert, setInputs }) {
  return (
    <>
      <Input
        id={name}
        error={!!alert}
        name={name}
        type={type}
        value={value || ''}
        onChange={setInputs}
        placeholder={alert || placeholder || ''}
        className={alert ? 'error' : ''} />
    </>
  )
}

export default TextInput
