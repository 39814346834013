import React, { useEffect, useState } from 'react'
import styled from "styled-components"
import theme from "~theme/theme"
import { v4 as uuidv4 } from 'uuid'

import CustomFormSubscription from "~components/CustomFormSubscription"
import Section from './section'
import submitFormModelCallback from './form-data'

const Form = ({
  data,
  ...props
}) => {
  useEffect(() => {
    setSubmitFormModel(submitFormModelCallback({ data }))
  }, [data])

  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const [submitFormModel, setSubmitFormModel] = useState(false)

  const [ID, setID] = useState(false)

  useEffect(() => {
    setID(uuidv4())
  }, [])

  const submitCallback = e => {
    const sendData = submitFormModel.sections.map(s =>
      s.fields.map(m => encodeURIComponent(m.name)+'='+encodeURIComponent(m.value)).join('&')
    ).join('&')

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `form-name=custom-form&id=${ID}&${sendData}`
    })
    .then(e => {
      setSuccess(true)
    })
    .catch(error => {
      setSubmitError(error)
    })

  }

  const [stepIndex, setStepIndex] = useState(0)
  const stepCount = submitFormModel && submitFormModel.sections.length
  const updateStepIndex = (index) =>  {
    if (index < stepCount) setStepIndex(index)
    else submitCallback()
  }

  return (
    <Wrapper>
      <figure>
        <img src={props.img} alt="" />
      </figure>
      {!submitFormModel &&
        <p>Loading...</p>
      }
      {success &&
        <CustomFormSubscription id={ID} />
      }
      {!!submitFormModel && !success &&
        <form method="post" name="custom-form" data-netlify="true">
          <p>I would love to return to a city that…</p>
          <input type="hidden" name="form-name" value="custom-form" />

          {submitFormModel && submitFormModel.sections.map((section, i) =>
            <Section
              visibility={!success}
              key={section.name}
              isCurrent={stepIndex===i}
              isLast={stepIndex===stepCount-1}
              section={section}
              submitCallback={() => updateStepIndex(i+1)}
            />
          )}
          {submitError &&
            <p>Error: {submitError}</p>
          }
        </form>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2rem 1rem 2rem 0;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column-reverse;
  }
  form {
    background-color: ${theme.colours.highlight};
    padding: 1rem;
    box-sizing: border-box;
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      margin-right: 0.5rem;
      width: 50%;
    }
    p {
      max-width: 100%;
    }
  }
`

export default Form
