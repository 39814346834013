import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'

import { Label } from '~theme/common'

function DropdownInput({
  name,
  label,
  type,
  placeholder,
  value,
  alert,
  options,
  setInputs,
  ...props
}) {

  const [selectedOptions, dispatch] = useReducer((selectedOptions, { option }) => {
    if (selectedOptions.indexOf(option) > -1) {
      return selectedOptions.filter((_) => option.value !== _.value);
  } else {
      return [...selectedOptions, option];
    }
  }, []);

  useEffect(() => {
    // Pretend to be a real input field event object
    setInputs({ target: { name, placeholder, value: selectedOptions.map(o => o.value).join(', ') }  })
  }, [selectedOptions]);

  const isSelected = (option) => {
    return selectedOptions.indexOf(option) > -1
  }

  const handleOptionChange = (option) => {
    dispatch({option})
  }

  return (
    <>
      <Label htmlFor={name}>{alert ? alert : label}</Label>
      <br />
      <br />
      <Options>
        {options.map((option, index) =>
        <Option key={index} onClick={() => handleOptionChange(option)} selected={isSelected(option)}>
            <span>{option.label}</span>
          </Option>
        )}
      </Options>
    </>
  )
}

const Options = styled.div`
  columns: 3;
  cursor: pointer;
  margin-bottom: 20px;
`

const Option = styled.p`
  span {
    border-bottom: 1px solid ${({selected}) => selected ? '' : 'transparent' };
  }
`

export default DropdownInput
