import React from 'react'

import styled from "styled-components"

import useForm from '~utils/use-form'

import { FormButton } from '~theme/common'
import TextInput from '~components/FormElements/TextInput'
import TextareaInput from '~components/FormElements/TextareaInput'
import DropdownInput from '~components/FormElements/DropdownInput'

const Section = ({
  visibility,
  section,
  submitCallback,
  isCurrent,
  isLast,
  ...props
}) => {
  const capitalize = expression => expression.charAt(0).toUpperCase() + expression.slice(1)
  const inputTypes = { TextInput, TextareaInput, DropdownInput }
  const renderInput = input => {
    const Input = inputTypes[capitalize(input.type) + 'Input']
    return <Input key={input.name} setInputs={setInputs} {...input} />
  }

  const [inputs, setInputs, setSubmit] = useForm(section.fields, submitCallback)

  return (
    <FormSection show={visibility}>
      {inputs.map(renderInput)}
      {visibility &&
        <FormButton onClick={setSubmit}>Submit</FormButton>
      }
    </FormSection>
  )
}

const FormSection = styled.div`
  visibility: ${({show}) => !!show ? 'visible' : 'hidden'}
`

export default Section

