import React, { useRef, useState } from "react"

import styled from "styled-components"

const VideoPlayer = ({ src, caption }) => {
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef()

  const startPlaying = () => {
    videoRef.current.play()
    setPlaying(true)
  }
  const stopPlaying = () => {
    videoRef.current.pause()
    setPlaying(false)
  }


  return (
    <>
      <VideoWrapper>
        <VideoControls playing={playing} onClick={playing ? stopPlaying : startPlaying}>
          {!!playing
            ?
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
            :
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="34"><path d="M8 5v14l11-7z"/></svg>
          }
        </VideoControls>
        <video ref={videoRef} controls={false} width="100%">
          <source src={src} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </VideoWrapper>
      {caption}
    </>
  )
}

const VideoWrapper = styled.div`
  position: relative;
  margin: 1rem 0 0.5rem;
`
const VideoControls = styled.div`
  color: white;
  font-size: 80px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({playing}) => playing ? '0' : '1' };
  &:hover {
    opacity: 1;
  }

  svg {
    width: 25%;
    height: 25%;
  }
  path {
    fill: #fff;
  }
`

export default VideoPlayer
