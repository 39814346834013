import React, { useRef } from 'react'
import { Label, Textarea, TextareaWrapper } from '~theme/common'

function TextareaInput({ name, label, placeholder, type, value, alert, setInputs }) {
  const textareaRef = useRef()

  return (
    <TextareaWrapper onClick={() => textareaRef.current && textareaRef.current.focus()}>
      <Textarea
        ref={textareaRef}
        rows={1}
        error={!!alert}
        id={name}
        name={name}
        value={value || ""}
        type={type}
        placeholder={alert || placeholder || ''}
        onChange={setInputs}
        className={alert ? 'error' : ''} />
    </TextareaWrapper>
  )
}

export default TextareaInput
