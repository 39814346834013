import React, { useState, useEffect } from "react"
import resolveLink from "~utils/resolveLink"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import sanityClient from '@sanity/client'
import Image from "~components/image"
import { css } from "@emotion/react"
import theme from "~theme/theme"

const mobile =  `@media(max-width: 600px)`

const client = sanityClient({
  projectId: '64znbptb',
  dataset: 'production',
  token: process.env.GATSBY_SANITY_API_TOKEN,
  useCdn: false
})

const SanityLink = ({ id, children }) => {
  const [to, setTo] = useState('/#')
  const [type, setType] = useState(undefined)
  useEffect(() => {
    if(id){
      client.fetch(`*[_id == '${id}']`).then(docs => {
        if(docs.length){
          setTo(resolveLink(docs[0]._type, docs[0].slug.current))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Link to={to}>
      {children}
    </Link>
  )
}

const serializers = {
  types: {
    articleImage: ({ node }) => {

      const widthOptions = {
        large: '100%',
        medium: 'calc(50% - 10px)',
        small: '25%',
      }

      return (
        <div css={css`
            margin: ${node.imageAlignment === 'right' ? '0.5rem 0' : '1rem 0'};
            width: ${node.imageAlignment === 'right' ? 'calc(33.33% - 40px)' : widthOptions[node.imageSize]};
            position: ${node.imageAlignment === 'right' ? 'absolute' : 'static'};
            right: 20px;
            ${mobile}{
              width: 100%;
              position: static;
              margin: 1rem 0 2rem;
            }
          `}>
          <figure>
            <Image id={node?.image?.asset?.id} src={node?.image?.asset?.url} alt={node.alt}/>
            {node.caption &&
              <figcaption>
                <p>
                  {node.caption}
                </p>
              </figcaption>
            }
          </figure>

        </div>
      )
    },
    articleQuote: ({ node }) => {
      return (
        <div css={css`
          /* margin: 1rem 0; */
          width: calc(33.33% - 40px);
          position: absolute;
          right: 20px;
          ${mobile}{
            width: 100%;
            position: static;
            right: 0;
            margin: 1rem 0 2rem;
          }
        `}>
          <p css={css`
            margin: 0.5rem 0 0 1rem;
            font-size: ${theme.typeSizes.large};
            ${mobile}{
              margin: 0;
            }
            `}>
            “{node.quote}”
          </p>
          {node.attribution &&
            <p css={css`
              font-size: ${theme.typeSizes.medium};
              margin: 1rem 0 0  1rem;
              `}>
              — {node.attribution}
            </p>
          }
        </div>
      )
    },
    twineGame: ({ node }) => {
      return (
        <iframe src={node.url} css={css`
          width: 100%;
          min-height: 70vh;
          border: 1px solid #000000;
          overflow: scroll;
        `}/>
      )
    },
  },
  marks: {
    internalLink: props => {
      return <SanityLink id={props.mark.page?._ref}>{props.children}</SanityLink>
    },
    fileLink: props => {
      return <a target="_blank" href={props.mark.file?.asset?.url}>{props.children}</a>
    },
  }
}

const RichTextArticle = ({ content, className }) => <BlockContent blocks={content} serializers={serializers} className={className}/>

export default RichTextArticle
