import React from 'react';
import { RichText, Elements } from 'prismic-reactjs';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const spanSerializer = function(type, element, content, children, key) {

  var props = {};
  switch(type) {

    // Add a class to paragraph elements
    case Elements.paragraph:
      props = {className: 'footnote'};
      return React.createElement('span', propsWithUniqueKey(props, key), children);

    // Return null to stick with the default behavior
    default:
      return null;
  }
};

export default spanSerializer
