import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import React, { useState } from "react"
import { css } from "@emotion/react"

const sanityConfig = {
  projectId: '64znbptb',
  dataset: 'production',
}

const Image = ({ id, className, width, alt, src, aspectRatio }) => {

  const [loaded, setLoaded] = useState(false)
  let fluidProps

  if (id && !/gif/.test(id)) {
    fluidProps = getFluidGatsbyImage(id, { maxWidth: width || 2400 }, sanityConfig)
    if(aspectRatio){
      fluidProps = {...fluidProps, aspectRatio}
    }
  }

  return (
    <div className={className ?? ''} css={css`position: relative`}>
      {fluidProps ? (
        <Img fluid={fluidProps} alt={alt} onLoad={() => setLoaded(true)} />
      ) : (
          <img
            alt={alt}
            src={src ? src : undefined}
            className={loaded ? 'loaded' : 'loading'}
            style={{width: '100%'}}
            onLoad={() => {
              setLoaded(true)
            }} />
        )}
    </div>
  )
}

export default Image
